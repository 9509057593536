import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Layout from '../components/layout'
import PageTitle from '../components/page-title'
import SEO from '../components/seo'
import { myContext } from '../../provider'

const Content = styled(Row)`
  margin: 3.125rem 0;
`
const Contact = ({ data }) => {
  return (
    <myContext.Consumer>
      {({ language }) => {
        const contactUsText = data.allContentfulContactUs.nodes.find(
          (node) => node.language === language
        )
        return (
          <Layout>
            <SEO title="Contact Us" />
            <PageTitle title={contactUsText.title} />
            <Container style={{ minHeight: '70vh' }}>
              <Row>
                <p>{contactUsText.description}</p>
              </Row>
              <Content>
                <Col xs={12} md={6}>
                  <Form method="POST" action="https://formspree.io/f/mleolyak">
                    <Form.Group>
                      <Form.Label>{contactUsText.nameLabel}</Form.Label>
                      <Form.Control
                        type="input"
                        name="Name"
                        placeholder={contactUsText.namePlaceholder}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{contactUsText.emailLabel}</Form.Label>
                      <Form.Control
                        type="email"
                        name="Email"
                        placeholder={contactUsText.emailPlaceholder}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{contactUsText.messageLabel}</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="Message"
                        placeholder={contactUsText.messagePlaceholder}
                      />
                    </Form.Group>
                    <Button type="submit">{contactUsText.submitLabel}</Button>
                  </Form>
                </Col>
                <Col xs={12} md={6}>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2604.2883827252726!2d-123.08239398372159!3d49.25197968086585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5486715520a4d66d%3A0x6e52e5ee687a68cc!2sVina%20Insurance%20Services%20Ltd.!5e0!3m2!1sen!2sca!4v1590984715212!5m2!1sen!2sca"
                    width="100%"
                    height="300"
                    frameborder="0"
                    style={{ border: 0 }}
                    allowfullscreen=""
                  ></iframe>
                  <div>
                    <p>
                      VINA INSURANCE SERVICES LTD. <br />
                      1192 Kingsway <br />
                      Vancouver, BC. V5V 3C8
                      <br />
                      Tel. 604 437 6669 <br />
                      Fax. 604 437 6668 <br /> <br />
                      Email. info@vina.ca <br />
                      <br />
                    </p>

                    <p>
                      Office hours
                      <br />
                      Monday - Friday : 9AM - 7PM
                      <br />
                      Saturday: 10AM - 6PM
                      <br />
                      Sunday: 11AM - 5PM
                      <br />
                      Holidays: Closed
                    </p>
                  </div>
                </Col>
              </Content>
            </Container>
          </Layout>
        )
      }}
    </myContext.Consumer>
  )
}

export const pageQuery = graphql`
  query ContactUsPageQuery {
    allContentfulContactUs {
      nodes {
        title
        submitLabel
        namePlaceholder
        nameLabel
        messagePlaceholder
        messageLabel
        emailPlaceholder
        emailLabel
        description
        language
      }
    }
  }
`
export default Contact
